import React from 'react';
import './Footer.scss';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const year = new Date().getFullYear();
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="logo-container">
          <img
            src="/images/minite-logo-footer.png"
            alt={t('footer.logo_alt')}
          />
        </div>

        <div className="routes-container">
          <ul>
            <li>
              <span className="routes-container-text">©Minite {year}</span>
            </li>
            <li>
              <a href="https://www.minite.works/privacy-policy" target="_blank">
                <span className="routes-container-text">
                  {t('privacy_policy')}
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.minite.works/code-of-conduct"
                target="_blank"
              >
                <span className="routes-container-text">
                  {t('code_of_conduct')}
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
