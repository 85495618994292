import React, { ReactElement, useEffect, useState } from 'react';
import './App.scss';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { protectedRoutes, unprotectedRoutes } from './routes';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import GenericModal from './components/generic-modal/GenericModal';
import { Auth, Hub } from 'aws-amplify';
import {
  UserData,
  fetchUserData,
  getUserType,
  resetPassword,
  signUpCompany,
  signUpStudent,
  validateUser,
} from './data/user';
import { useUserData } from './stores/auth';
import { Routes as NavigationRoutes } from './utils/consts';
import MiniteSnackbar from './components/snackbar';
import { CircularProgress } from '@mui/material';
import { Header } from './components/header/Header';
import { SubFooter } from './components/sub-footer/SubFooter';
import { Footer } from './components/footer/Footer';
import Maintenance from './pages/maintenance';
import { useTranslation } from 'react-i18next';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import Community from './components/community';
import { FormFields } from './components/sign-up/FormFields';
import {
  fetchStudentById,
  studentRegistrationNotComplete,
} from './data/profile';
import { sleep } from './utils/helpers';
import useDocumentTitle from './utils/useDocumentTitle';
import { ForceNewPasswordFields } from './components/amplify/force-new-password';
import { ConfirmResetPasswordFooter } from './components/amplify/confirm-reset-password';
import { BackButton } from './components/amplify/controls/BackButton';
import { isNotVerifiedCompany } from './data/companies';
import { TermsAndConditionsModal } from './components/terms-and-conditions-modal/TermsAndConditionsModal';
import VoteModal from './components/vote-modal/VoteModal';
import { NotificationHeader } from './components/notification-header/NotificationHeader';
import {
  blockAccessUnverified,
  UnverifiedModal,
} from './components/unverified-modal/UnverifiedModal';
import { useAnnouncementDetailsUser } from './data/announcements';

const SetTitle = ({ page }: { page: string }) => {
  useDocumentTitle(page);
  return <></>;
};

const App = (): ReactElement => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  I18n.putVocabularies(translations);
  I18n.setLanguage(language);

  I18n.putVocabularies({
    nl: {
      'Sign Up with Google': t('sign_up.google_sign_up'),
      'It may take a minute to arrive.': t('reset_pass.minute_to_arrive'),
      'Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.': `${t(
        'reset_pass.code_on_way',
      )} ${t('reset_pass.enter_code_send')}. ${t(
        'reset_pass.minute_to_arrive',
      )}`,
      'Back to Sign In': t('general.back'),
    },
  });

  I18n.putVocabulariesForLanguage('en', {
    Code: t('reset_pass.code_sent_email'),
    'Enter your username': t('reset_pass.enter_email'),
    'Confirm Password': t('reset_pass.confirm_pass'),
    'Back to Sign In': t('general.back'),
    'New Password': t('reset_pass.new_password'),
  });

  I18n.putVocabulariesForLanguage('nl', {
    Code: t('reset_pass.code_sent_email'),
    'Enter your username': t('reset_pass.enter_email'),
    'We Emailed You': t('reset_pass.emailed_you'),
    'Your code is on the way. To log in, enter the code we emailed to': `${t(
      'reset_pass.code_on_way',
    )} ${t('reset_pass.enter_code_emailed')}`,
    'It may take a minute to arrive': t('reset_pass.minute_to_arrive'),
    'New Password': t('reset_pass.new_password'),
    'Confirm Password': t('reset_pass.confirm_pass'),
    'We Sent A Code': t('reset_pass.send_code'),
    'Your code is on the way. To log in, enter the code we sent you': `${t(
      'reset_pass.code_on_way',
    )}. ${t('reset_pass.enter_code_send')}`,
    'Back to Sign In': t('general.back'),
  });

  const formFields = {
    signIn: {
      username: {
        placeholder: 'Email',
        isRequired: true,
        label: 'Email',
      },
    },
    signUp: {
      username: {
        placeholder: 'Email',
        isRequired: true,
        label: 'Email',
      },
    },
  };

  const setUserData = useUserData((state) => state.setUserData);
  const user = useUserData((state) => state.user);
  const logged_in = useUserData((state) => state.logged_in);
  const userData = useUserData((state) => state.user);
  const { data: announcement } = useAnnouncementDetailsUser('banner_students');
  const refetchUser = useUserData((state) => state.refetchUser);
  const setLoggedIn = useUserData((state) => state.setLoggedIn);
  const [showLogoutButtonInHeader, setShowLogoutButtonInHeader] =
    useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { toSignIn } = useAuthenticator();

  const [stillCanVote, setStillCanVote] = useState(false);
  const [showVoteModal, setShowVoteModal] = useState(false);
  const d = new Date();
  const month = d.getMonth();

  const handleUnverifiedModalCancel = () => {
    return navigate(-1);
  };

  const handleUnverifiedModalConfirm = () => {
    return navigate(NavigationRoutes.ProfileSettingsRoute, { replace: true });
  };

  const handleVoteCancel = () => {
    setShowVoteModal(false);
    localStorage.setItem('closedModalNew', 'closed');
  };

  // Loading internal user, signup logic
  const init = async () => {
    // Logout a user from tab if session is invalid
    document.addEventListener('visibilitychange', async () => {
      if (document.visibilityState === 'visible') {
        try {
          await Auth.currentAuthenticatedUser({ bypassCache: true });
        } catch (error) {
          await Auth.signOut();
        }
      }
      try {
        await refetchUser();
      } catch (error) {
        const status = error?.response?.status;
        if (
          window.location.pathname !== NavigationRoutes.SelectRole ||
          status !== 404
        ) {
          setErrorMessage(
            error.response?.data?.error.message || error?.message?.toString(),
          );
        }
      }
    });

    // addEventListener('storage', async (event) => {
    //   if (event.key.includes('Cognito') && event.newValue === null) {
    //     await Auth.signOut();
    //   }
    // });

    // Referrals
    const { search } = location;
    const params = search.split('&');
    for (const param of params) {
      if (param.includes('ref')) {
        const [, ref_code] = param.split('=');
        localStorage.setItem('ref_code', ref_code);
      } else if (param.includes('scroll')) {
        const [, scrollTo] = param.split('=');
        sessionStorage.setItem('scrollTo', scrollTo);
      }
    }

    try {
      setIsLoading(true);

      const cognitoUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      try {
        const userData = (await fetchUserData()) as UserData;
        if (!userData.manager_uid) {
          localStorage.setItem('isOrdinaryUser', '1');
        } else {
          localStorage.removeItem('isOrdinaryUser');
        }
        const type = getUserType(userData);
        const token = cognitoUser.signInUserSession.accessToken.jwtToken;

        setUserData({
          type,
          token,
          ...userData,
        });

        setLoggedIn(true);

        if (userData.student_uid) {
          const student = await fetchStudentById(userData.student_uid);
          if (!userData.verified && studentRegistrationNotComplete(student)) {
            setShowLogoutButtonInHeader(true);
            navigate(NavigationRoutes.SignUpStudentInfo);
          } else {
            if (location.pathname === NavigationRoutes.DashboardRoute) {
              navigate(`${NavigationRoutes.DashboardRoute}?student`);
              const scrollTo = sessionStorage.getItem('scrollTo');
              if (scrollTo) {
                const element = document.getElementById(scrollTo);
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }
          }
        } else if (
          userData.company_uid &&
          (!userData.company.company_name ||
            !userData.city ||
            !userData.kvk_number) &&
          !userData.manager_uid
        ) {
          setShowLogoutButtonInHeader(true);
          navigate(NavigationRoutes.SignUpCompanyInfo);
        } else {
          // Do not redirect admins created using a company
          if (
            userData.company_uid &&
            !userData.manager_uid &&
            location.pathname === NavigationRoutes.DashboardRoute
          ) {
            navigate(`${NavigationRoutes.DashboardRoute}?company`);
          }
        }

        const targetPath = localStorage.getItem('targetPath');
        localStorage.removeItem('targetPath');
        if (targetPath) {
          navigate(targetPath);
        }
      } catch (error) {
        const status = error?.response?.status;
        setShowLogoutButtonInHeader(true);
        if (status === 404) {
          try {
            // Check if there is an existing user with same email
            await validateUser(cognitoUser);
            setLoggedIn(true);

            const { attributes } = cognitoUser;
            const userType = attributes
              ? attributes['custom:signup']
              : 'unknown';
            const phone = attributes ? attributes['custom:phone'] : null;
            const how_did_you_find_minite = attributes
              ? attributes['custom:how_did_you_find_us']
              : null;

            const country = attributes ? attributes['custom:country'] : null;

            // Used later when creating a user
            // If the user is from external provider
            // this will be set on the select role page

            const invited_by_ref_code = localStorage.getItem('ref_code');

            const signup_data = {
              user: {
                phone,
                invited_by_ref_code,
                how_did_you_find_minite,
                country,
              },
            };

            switch (userType) {
              case 'company':
                navigate(NavigationRoutes.SignUpCompanyInfo);
                await signUpCompany(signup_data);
                // send to google analytics
                const companySignUpTracker = document.querySelector(
                  '#track-company-land-on-kvk-details-page',
                ) as HTMLInputElement;

                companySignUpTracker.click();

                await refetchUser();
                setLoggedIn(true);
                localStorage.removeItem('ref_code');
                break;
              case 'student':
                navigate(NavigationRoutes.SignUpStudentInfo);
                await signUpStudent(signup_data);
                // send to google analytics
                const studentSignUpTracker = document.querySelector(
                  '#track-student-land-on-student-details-page',
                ) as HTMLInputElement;
                studentSignUpTracker.click();
                await refetchUser();
                setLoggedIn(true);
                localStorage.removeItem('ref_code');
                break;
              default:
                // Allow users to select which type of user they want to be if
                // they cannot be identified from cognito user attributes
                setLoggedIn(true);
                navigate(NavigationRoutes.SelectRole);
                break;
            }
          } catch (error) {
            setIsLoading(false);
            const status = error?.response?.status;

            if (status !== 404) {
              setErrorMessage(
                error.response?.data?.error.message || error.message.toString(),
              );
            }
            if (status === 403) {
              await sleep(3000);
              await Auth.signOut();
            }
          }
        } else if (status === 401) {
          setIsLoading(false);
          setErrorMessage(
            error.response?.data?.error.message || error.message.toString(),
          );
          await sleep(3000);
          await Auth.signOut();
        } else {
          setIsLoading(false);
          const status = error?.response?.status;

          if (status !== 404) {
            setErrorMessage(
              error.response?.data?.error.message || error.message.toString(),
            );
          }
        }
      }
    } catch (error) {
      console.error('Error: ', error);
      // for redirection to correct page
      if (location.pathname.length > 1) {
        localStorage.setItem('targetPath', location.pathname);
      }
    } finally {
      setIsLoading(false);
      // Notify listeners outside of react app
      window.dispatchEvent(new Event('minite.init'));
    }
  };

  useEffect(() => {
    if (!logged_in) {
      setUserData(null);
    }
  }, [logged_in]);

  const resendSignup = async (username) => {
    try {
      await Auth.resendSignUp(username);
    } catch (error) {
      setErrorMessage('Failed to resend verification code');
      toSignIn();
    }
  };

  useEffect(() => {
    if (user && user.token) {
      refetchUser();
    }

    const checkStudentRegistration = async () => {
      const student = await fetchStudentById(userData?.student_uid);
      if (!userData.verified && studentRegistrationNotComplete(student)) {
        setShowLogoutButtonInHeader(true);
        navigate(NavigationRoutes.SignUpStudentInfo);
      }
    };

    if (
      !(
        location.pathname === '/' ||
        location.pathname === '/jobs' ||
        location.pathname === '/signup/student/info' ||
        unprotectedRoutes
          .map((route) => route.path)
          .includes(window.location.pathname)
      )
    ) {
      checkStudentRegistration().catch((error) => {
        console.error(error);
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (user?.email) {
      (async () => {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          // Only check for non google users since google users are not allowed
          // to change emails
          if (!cognitoUser.username.includes('google_')) {
            const userEmail = localStorage.getItem('username');
            if (
              userEmail &&
              user.email.toLowerCase() !== userEmail.toLowerCase()
            ) {
              await Auth.signOut();
            }
          }
        } catch (error) {
          await Auth.signOut();
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    // Note: gets called on load
    if (
      !unprotectedRoutes
        .map((route) => route.path)
        .includes(window.location.pathname)
    ) {
      (async () => {
        await init();
      })();
    }

    Hub.listen(/.*/, async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          await init();
          break;
        case 'signUp':
          localStorage.removeItem('targetPath');

          const userType = await localStorage.getItem('user_type');

          switch (userType) {
            case 'company':
              {
                const companySignUpTracker = document.querySelector(
                  '#track-company-land-on-verify-email',
                ) as HTMLInputElement;
                companySignUpTracker.click();
              }
              break;
            case 'student':
              {
                const studentSignUpTracker = document.querySelector(
                  '#track-student-land-on-verify-email',
                ) as HTMLInputElement;
                studentSignUpTracker.click();
              }
              break;
          }
          localStorage.removeItem('user_type');
          break;
        case 'signOut':
          setLoggedIn(false);
          localStorage.removeItem('username');
          navigate('/');
          break;
        case 'signIn_failure':
          setUserData(null);
          if (data.payload.data.code === 'UserNotConfirmedException') {
            const username = localStorage.getItem('username');
            await resendSignup(username);
            localStorage.removeItem('username');
          }
          break;
        case 'configured':
          break;
        case 'forgotPasswordSubmit':
          setMessage('Password reset complete!');
          break;
      }
    });

    const now = new Date();
    const voteEnd = new Date('2024-02-22T09:59:59.999Z');
    if (now < voteEnd) {
      setStillCanVote(true);
      const closedModal = localStorage.getItem('closedModalNew');
      if (closedModal === null) {
        setShowVoteModal(true);
      } else {
        setShowVoteModal(false);
      }
    }
    if (location.pathname === '/login' || location.pathname === '/signup') {
      navigate(`${NavigationRoutes.DashboardRoute}`);
    }
  }, []);

  const services = {
    async handleForgotPassword(username) {
      try {
        const {
          data: {
            data: { userExists, changed },
          },
        } = await resetPassword(username);

        if (changed) {
          setMessage('Password reset successful! Please check your email.');
          toSignIn();
        }

        if (userExists && !changed) {
          await Auth.forgotPassword(username);
          setMessage('Code sent! Please check your email.');
        }

        if (!userExists) {
          setErrorMessage(
            'Email address is not registered or try to login with Google!',
          );
          toSignIn();
          return;
        }
      } catch (error) {
        const status = error?.response?.status;

        if (status !== 404) {
          setErrorMessage(
            error.response?.data?.error.message || error.message.toString(),
          );
        }
        toSignIn();
        return;
      }
    },

    async handleSignIn({ username, password }) {
      localStorage.setItem('username', username);
      return Auth.signIn({ username, password });
    },
  };

  return (
    <>
      {/* Start google analytics tracking */}
      <input type="hidden" id="track-company-land-on-kvk-details-page" />
      <input type="hidden" id="track-company-land-on-verify-email" />
      <input type="hidden" id="track-student-land-on-verify-email" />
      <input type="hidden" id="track-student-land-on-student-details-page" />
      {/* End google analytics */}
      {user?.student_uid &&
        (announcement?.visible === true ? (
          <NotificationHeader
            content={announcement?.value}
            redirectUrl={announcement?.url}
          />
        ) : (
          <></>
        ))}
      {user?.student_uid &&
        ((month === 0 || month === 3 || month === 6 || month === 9) &&
        announcement?.visible == false ? (
          <NotificationHeader
            content={t('header.tax_window')}
            redirectUrl="https://www.minite.works/kb-articles-highflyers/hoe-doe-ik-mijn-btw-aangifte"
          />
        ) : (
          <></>
        ))}
      <Header showLogoutButton={showLogoutButtonInHeader} />
      {!unprotectedRoutes
        .map((route) => route.path)
        .includes(window.location.pathname) ? (
        <div className="auth-wrapper">
          <Authenticator
            initialState="signUp"
            socialProviders={['google']}
            formFields={formFields}
            services={services}
            components={{
              SignUp: {
                FormFields,
              },
              ConfirmResetPassword: {
                Footer: ConfirmResetPasswordFooter,
              },
              ForceNewPassword: {
                FormFields: ForceNewPasswordFields,
              },
              ConfirmSignUp: {
                Footer: () => <BackButton page="Confirm Sign Up" />,
              },
              VerifyUser: {
                Footer: () => <BackButton page="Verify User" />,
              },
              ResetPassword: {
                Footer: () => <SetTitle page="Sign In" />,
              },
              SignIn: {
                Header: () => <SetTitle page="Sign In" />,
              },
            }}
            className="sides"
          >
            {logged_in ? (
              <Routes>
                {protectedRoutes.map(
                  ({ path, requiresVerified, Component }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <Layout>
                          {!userData?.verified &&
                          blockAccessUnverified(requiresVerified, userData) ? (
                            <UnverifiedModal
                              cancelAction={handleUnverifiedModalCancel}
                              confirmAction={handleUnverifiedModalConfirm}
                            />
                          ) : (
                            <Component />
                          )}
                        </Layout>
                      }
                    />
                  ),
                )}
                <Route
                  path={NavigationRoutes.Maintenance}
                  element={<Maintenance />}
                />
              </Routes>
            ) : (
              <div className="progress-container">
                {isLoading && (
                  <>
                    <div>
                      <CircularProgress />
                    </div>
                    <p>{'Loading...'}</p>
                  </>
                )}
              </div>
            )}
          </Authenticator>
          {!logged_in && !isLoading && <Community />}
        </div>
      ) : (
        <div>
          <Routes>
            {unprotectedRoutes.map(({ path, Component }) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
          </Routes>
        </div>
      )}

      <MiniteSnackbar
        message={errorMessage}
        title={'Oops :('}
        open={!!errorMessage}
        onClose={() => setErrorMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
      <MiniteSnackbar
        message={message}
        title={'Message'}
        open={!!message}
        severity="success"
        onClose={() => setMessage(null)}
        location={{ vertical: 'top', horizontal: 'center' }}
        offsetTopValue={55}
        cancelOffsetIfNotAtTop={true}
      />
      {isNotVerifiedCompany(user) && (
        <GenericModal
          open
          title="Your registration is currently pending review."
          content="Your company already has an active Minite account. Your registration is currently pending review. Once approved, you will have access to your account."
          confirmButtonText="Logout"
          handleConfirm={async () => {
            await Auth.signOut();
          }}
        />
      )}
      {userData &&
        !userData.accepted_terms_and_conditions &&
        !userData.manager_uid && (
          <TermsAndConditionsModal title="" user={userData} />
        )}
      <VoteModal
        open={showVoteModal && stillCanVote && !!user?.student_uid}
        handleCancel={handleVoteCancel}
      />
      <SubFooter />
      <Footer />
    </>
  );
};

export default App;
